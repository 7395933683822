import React, { Component } from 'react'
import FilterCheckInAndOut from './FilterCheckInAndOut';
import FilterLocation from './FilterLocation';

import Listings from '../listings/Listings';

export class FirstPage extends Component {
  render() {
    const site = this.props.site;
    const language = this.props.language;

    return (
      <div>
        <div className="relative max-w-8xl py-3 md:py-12 px-4 md:px-6 xl:px-0 m-auto">
          <div className="max-w-5xl pb-6">
            <div className="max-w-3xl">
              <h1 className="pb-4">{site?.first_page_title}</h1>
            </div>
            <p className="text-xl leading-8	">{site?.first_page_description}</p>
          </div>


          <Listings
            site={site}
            language={language}
            pageType="firstPage"
            location={this.props.location}
          />
        </div>
      </div>
    );
  }
}

export default FirstPage