import React, { Component } from 'react'
import ArticialIntelligence from './ArticialIntelligence'

import About from './About'
import Values from './Values'

export class StaticPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: ""
    }
    this.renderContent = this.renderContent.bind(this);
  }
  renderContent = () => {
    const action_name = this.props.pageInformation.action_name
    if (action_name === "about"){
      return <About site={this.props.site} />;
    }

    else if (action_name === "values"){
      return <Values site={this.props.site} />; 
    }
  
    else {}
  }
  render() {
    const site = this.props.site;
    const language = this.props.language;

    return (
        <div>{
          this.props.pageInformation.action_name === "ai" ? 
          <ArticialIntelligence language={language} site={site} /> : 
          <div className="relative max-w-3xl py-12 px-4 md:px-6 xl:px-0 m-auto SP-common-text leading-8	">
            {this.renderContent()}
          </div>
          }
        </div>
    );
  }
}

export default StaticPages