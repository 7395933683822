import React, { Component } from 'react'

import NavigationRegular from './navigation/NavigationRegular'
import Index, { FirstPage } from './main/FirstPage'
import StaticPages from './main/StaticPages'
import DynamicPage from './main/DynamicPage'
import ListingPage from './listings/ListingPage'

import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import axios from 'axios'

const csrfToken = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      site: props.site || {},
      pageInformation: props.pageInformation || {},
      language: {},
      search: "",

      visibility: {
        search: false,
        menu: false,
      },

      availability: {
        checkinDate: "",
        checkoutDate: "",
        guests: 2,
      },

      active_filters: {
        locations: [],
        filters: [],
      },
    };

    this.loadSiteData = this.loadSiteData.bind(this);
    this.showThisComponent = this.showThisComponent.bind(this);
    this.loadPageInformation = this.loadPageInformation.bind(this);
  }

  componentDidMount() {
    this.loadSiteData();
    this.loadPageInformation();

     if (!this.props.site) {
       this.loadSiteData();
     }
     if (!this.props.pageInformation) {
       this.loadPageInformation();
     }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadPageInformation();
    }
  }

  async loadSiteData() {
    var self = this;

    await axios
      .post(`/api/site`, { url: window.location.href })
      .then((response) => {
        self.setState({ site: response.data, loading: false });
      });
  }

  async loadPageInformation() {
    var self = this;

    const currentUrl = window.location.href;
    console.log(currentUrl);

    const slug =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];

    await axios
      .post(`/api/page-information`, { url: window.location.href, slug })
      .then((response) => {
        self.setState({
          pageInformation: response.data,
        });
      });
  }

  showThisComponent = (component) => {
    this.setState({
      visibility: {
        [component]: !this.state.visibility[component],
      },
    }, () => {
      if (component == "search") {
        const searchInput = document.querySelector(".VisibilitySearch input");
        searchInput?.focus();
      }
    });
  };

  render() {
    const site = this.state.site;
    const language = site.translation;
    const pageInformation = this.state.pageInformation;
    return (
      <div>
        <Helmet>
          <html lang={pageInformation?.hreflang} />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=5.0, minimum-scale=1.0, viewport-fit=cover"
          />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="robots" content="all" />

          <title>{pageInformation?.meta_title}</title>
          <meta
            name="description"
            content={pageInformation?.meta_description}
          />

          <link rel="canonical" href={pageInformation?.canonical} />

          <meta
            httpEquiv="Content-Language"
            content={pageInformation?.language_code}
          />

          {/* OpenGraph tags for social media */}
          <meta property="og:title" content={pageInformation?.meta_title} />
          <meta
            property="og:description"
            content={pageInformation?.meta_description}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />

          {/* Optional: Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageInformation?.title} />
          <meta
            name="twitter:description"
            content={pageInformation?.description}
          />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={pageInformation?.icon}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={pageInformation?.icon}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={pageInformation?.icon}
          />

          <meta charSet="UTF-8" />
          <meta property="og:site_name" content={pageInformation?.site_name} />
          <meta property="og:title" content={pageInformation?.title} />
          <meta
            property="og:description"
            content={pageInformation.description}
          />

          <meta property="og:url" content={pageInformation?.canonical} />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content={pageInformation?.language_code} />

          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${pageInformation?.ga_code}`}
          ></script>

          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${pageInformation?.ga_code}');
            `}
          </script>
        </Helmet>

        <NavigationRegular
          site={site}
          language={language}
          visibility={this.state.visibility}
          showThisComponent={this.showThisComponent}
        />

        <Routes>
          <Route
            path="/"
            element={
              <FirstPage
                language={language}
                site={site}
                location={this.props.location}
              />
            }
          />
          <Route
            path="/values"
            element={
              <StaticPages
                pageInformation={pageInformation}
                language={language}
                site={site}
              />
            }
          />
          <Route
            path="/about"
            element={
              <StaticPages
                pageInformation={pageInformation}
                language={language}
                site={site}
              />
            }
          />
          <Route
            path="/ai"
            element={
              <StaticPages
                pageInformation={pageInformation}
                language={language}
                site={site}
              />
            }
          />

          <Route
            path="/:slug"
            element={
              <DynamicPage
                pageInformation={pageInformation}
                language={language}
                site={site}
                location={this.props.location}
              />
            }
          />

          <Route
            path="/listing/:slug"
            element={
              <ListingPage 
                pageInformation={pageInformation}
                language={language}
                site={site}
                location={this.props.location}
              />
            }
          />
        </Routes>
      </div>
    );
  }
}

export default App