import React, { Component } from 'react'

export class Values extends Component {
  render() {
    const site = this.props.site;
    return (
      <div>
        <h1>{site?.values_title}</h1>
        <div dangerouslySetInnerHTML={{ __html: site?.values}} />
      </div>
    );
  }
}

export default Values