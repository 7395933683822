import axios from 'axios';
import React, { Component } from 'react'

import { Link } from 'react-router-dom';

export class Listing extends Component {
  render() {
    const listing = this.props.listing;
    const site = this.props.site;
    const language = this.props.language;
    const cover_image =
      listing?.listing_photo_urls ? listing?.listing_photo_urls[
        listing?.selected_image_index
      ] : "https://via.placeholder.com/150";
    const multiple_images = this.props.multiple_images;
    const buttonNavigationImageCss = `absolute top-1/2 transform -translate-y-1/2 flex items-center justify-center bg-white p-2 rounded-full shadow-lg text-gray-600 hover:text-gray-800 text-xl focus:outline-none`;
    return (
      <div
        className="Listing col-span-6 md:col-span-4  ursor-pointer rounded-md  transition duration-300 ease-in-out"
        onClick={(e) => e.preventDefault()}
        key={listing?.name}
      >
        <div className="w-full relative">
          {multiple_images && (
            <>
              <button
                className={`NavigateImageNextPrev right-3.5 ${buttonNavigationImageCss}`}
                style={{ width: "26px", height: "26px" }}
                onClick={(e) =>
                  this.props.navigateImageForListing(listing.id, "next")
                }
              >
                &gt;
              </button>
              <button
                className={`NavigateImageNextPrev left-3.5 ${buttonNavigationImageCss}`}
                style={{ width: "26px", height: "26px" }}
                onClick={(e) =>
                  this.props.navigateImageForListing(listing.id, "previous")
                }
              >
                &lt;
              </button>
            </>
          )}
          {multiple_images && <div
            className={`grid grid-cols-12 gap-2 absolute top-4 w-full px-4 md:px-6`}
          >
            <div
              className={`cursor-pointer col-span-3 py-0.5 bg-white rounded-full ${
                listing?.selected_image_index === 0 ? "" : "opacity-50"
              }`}
            ></div>
            <div
              className={`cursor-pointer col-span-3 py-0.5 bg-white rounded-full ${
                listing?.selected_image_index === 1 ? "" : "opacity-50"
              }`}
            ></div>
            <div
              className={`cursor-pointer col-span-3 py-0.5 bg-white rounded-full ${
                listing?.selected_image_index === 2 ? "" : "opacity-50"
              }`}
            ></div>
            <div
              className={`cursor-pointer col-span-3 py-0.5 bg-white rounded-full ${
                listing?.selected_image_index === 3 ? "" : "opacity-50"
              }`}
            ></div>
          </div>}

          {listing && listing?.listing_photo_urls && (
            <Link to={`/listing/${listing?.slug}`}>
              <img
                src={cover_image}
                alt={listing?.name}
                className={`w-full object-cover rounded-xl `}
                loading="lazy"
                style={{ height: "200px" }}
              />
            </Link>
          )}
          {listing?.highlight && (
            <div className="absolute top-5 left-0">
              <div className="px-2 py-1 bg-white text-gray-600 text-xs rounded-br-md rounded-tr-md font-semibold">
                {listing?.highlight}
              </div>
            </div>
          )}

          <Link
            to="/"
            className="cap px-2 py-1.5 rounded-full text-xs font-semibold bg-white hover:bg-white text-gray-600 absolute bottom-2 right-2"
          >
            {language?.available} {language?.rooms}
          </Link>
        </div>

        <div className="w-full py-5 px-2">
          <Link to="#">
            <div className="font-bold pb-0.5 text-lg w-full truncate">
              {listing.name}
            </div>
          </Link>
          <p className="mb-0 pb-0 text-sm text-gray-600">
            {listing?.filter_summary_list}
          </p>

          <div className="grid grid-cols-1 gap-4 md:gap-0 md:flex items-center mt-2">
            <div className="cap">
              <span className=" font-bold text-gray-600">
                {language?.prices} {language?.from}:
              </span>
              <span className="text-green-700 font-bold ml-1">
                12,500:-
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Listing