import React, { Component } from 'react'
import axios from 'axios';

export function flag(language) {
  return {
    __html: language?.locals_svg_icon,
  };
};

export class MenuLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      loading: true,
    };

    this.loadRelatedLanguages = this.loadRelatedLanguages.bind(this);
  }

  loadRelatedLanguages() {
    axios.get(`/api/languages`)
    .then(response => {
      this.setState({
        sites: response.data,
        loading: false,
      })
    })
  }

  render() {
    const site = this.props.site;
    const language = this.props.language;
    
    return (
      site.multiple_languages && (
        <div className="sm:relative">
          <div
            className="cursor-pointer px-2 lg:px-5 flex items-center"
            style={{ height: "40px" }}
            onClick={(e) => {
              this.props.showThisComponent("location");
              this.loadRelatedLanguages();
            }}
          >
            <span
              className="ml-2 mr-2.5 text-gray-500 font-semibold cap cursor-pointer flex items-center"
              style={{ height: "40px" }}
            >
              <div dangerouslySetInnerHTML={flag(language)}></div>
            </span>
          </div>

          {this.props.visibility.location && this.state.loading === false && (
            <>
              <div
                className="VisibleLocation z-5 absolute border-1 top-6 md:top-2 right-3 sm:right-0 mt-12 py-2 bg-white rounded-lg shadow-xl border border-gray-200 overflow-scroll"
                style={{ minWidth: "320px" }}
              >
                {this.state.sites &&
                  this.state.sites.map((site, index) => {
                    return (
                      <a
                        key={index}
                        className="flex items-center px-4 py-2.5 hover:bg-gray-50 "
                        target="_blank"
                        href={site.url}
                      >
                        <span className="ml-2 mr-2.5 text-gray-500 font-semibold cap cursor-pointer">
                          {site?.translation?.locals_svg_icon?.length > 0 && (
                            <div
                              dangerouslySetInnerHTML={flag(site?.translation)}
                            ></div>
                          )}
                        </span>
                        <span className="text-gray-500 font-semibold cap cursor-pointer whitespace-nowrap">
                          {site.translation?.locale_value}
                        </span>
                      </a>
                    );
                  })}
              </div>
              <div
                className="popup-bg-transparent"
                onClick={(e) => this.props.showThisComponent("location")}
              ></div>
            </>
          )}
        </div>
      )
    );
  }
}

export default MenuLocation