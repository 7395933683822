import React, { Component } from 'react'
import Listing from './Listing'

import axios from 'axios';

export class Listings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listings: [],
      loading: true,
    };
    this.fetchListings = this.fetchListings.bind(this);
    this.navigateImageForListing = this.navigateImageForListing.bind(this);
  }

  componentDidMount() {
    this.fetchListings();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.fetchListings();
    }
  }

  fetchListings() {
    axios
      .post(`/api/listings`, { url: window.location.href })
      .then((response) => {
        this.setState({ listings: response.data, loading: false });
      })
      .catch((error) => {
        alert(error);
        this.setState({ loading: false });
      });
  }

  navigateImageForListing = (listing_id, direction) => {
    this.setState((prevState) => {
      // Clone the listings array from state
      const updatedListings = [...prevState.listings];
      
      // Find the index of the listing with the given ID
      const listingIndex = updatedListings.findIndex(
        (listing) => listing.id === listing_id
      );
      
      if (listingIndex === -1) {
        // Listing not found
        return null;
      }
      
    // Clone the specific listing object
    const listing = { ...updatedListings[listingIndex] };

    // Get the current index and the last index of the photos
    const lastIndex = listing.listing_photo_urls.length - 1;
    let newIndex;

    if (direction === "next") {
      newIndex = listing.selected_image_index === lastIndex ? 0 : listing.selected_image_index + 1;
    } else if (direction === "previous") {
      newIndex = listing.selected_image_index === 0 ? lastIndex : listing.selected_image_index - 1;
    } else {
      newIndex = listing.selected_image_index; // No change for invalid direction
    }

    // Update the selected_image_index in the cloned listing
    listing.selected_image_index = newIndex;

    // Replace the old listing with the cloned and modified listing
    updatedListings[listingIndex] = listing;

    // Return the updated listings array to set the new state
    return { listings: updatedListings };
    });
  };

  render() {
    const site = this.props.site;
    const language = this.props.language;
    return (
      <div>
        {this.state.loading == true ? "Laddar nu..." : ""}
        <div className="Listings grid grid-cols-12 gap-3 md:gap-8">
          {this.state.listings.map((listing) => (
            <Listing
              key={listing.id}
              listing={listing}
              site={site}
              language={language}
              navigateImageForListing={this.navigateImageForListing}
              multiple_images={true}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Listings