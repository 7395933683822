import React, { Component } from 'react'
import Logo from './Logo';
import Search from './Search';
import MenuRegular from './MenuRegular';
import MenuLocation from './MenuLocation';
import Match from '../features/Match';

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export class NavigationRegular extends Component {
  render() {
    const site = this.props.site
    const language = this.props.language;
    return (
      <div className="relative NavigationRegular" id="NavigationRegular">
        <img
          src={`https://s3.eu-west-2.amazonaws.com/byggservice/photos/images/000/025/233/large/Background_gradient2x_%281%29.png?1690964537`}
          alt={site.name}
          draggable="false"
          className="NavigationBackgroundImg absolute top-0 right-0 object-cover z-0 select-none"
        />

        <div
          className={`p-3 md:p-6 relative bg-white`}
          style={{ borderBottom: "1px solid #e6e6e6" }}
        >
          <div className="flex justify-between items-center">
            <Logo language={language} site={site} link="/" />

            <div className="flex items-center">
              <Link className="text-lg p-3" to="/ai">
                Ai
              </Link>

              <span
                onClick={(e) => this.props.showThisComponent("match")}
                className="text-lg mr-3 sm:mr-4 lg:mr-5 cursor-pointer p-3 hidden"
              >
                Match
              </span>
              <Match
                site={site}
                visibility={this.props.visibility}
                showThisComponent={this.props.showThisComponent}
                language={language}
              />

              <MenuLocation
                site={site}
                visibility={this.props.visibility}
                showThisComponent={this.props.showThisComponent}
                language={language}
              />

              <MenuRegular
                site={site}
                visibility={this.props.visibility}
                showThisComponent={this.props.showThisComponent}
                language={language}
              />
            </div>
          </div>
        </div>

        <Search
          site={site}
          visibility={this.props.visibility}
          showThisComponent={this.props.showThisComponent}
          language={language}
        />
      </div>
    );
  }
}

export default NavigationRegular