import React from "react";
import { useLocation } from "react-router-dom";
import App from "./App";

const AppWrapper = (props) => {
  const location = props.location || useLocation();

  // Merge any other props passed in during server-side rendering
  const mergedProps = {
    ...props,
    location,
  };

  return <App {...mergedProps} />;
};

export default AppWrapper;