import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


import { Icon } from "../utils/utils";

export class MenuRegular extends Component {
  render() {
    const site = this.props.site;
    const language = this.props.language;
    const activeMenu = this.props.visibility.menu

    return (
      <div className="relative">
        <div className="flex items-center" style={{ height: "40px" }}>
          <span
            className={`border border-1 rounded-full flex items-center justify-center p-2 cursor-pointer select-none ml-4 ${
              activeMenu
                ? `border-${site.primary_color}-400 bg-${site.primary_color}-50`
                : "bg-gray-50 border-gray-200"
            }`}
            onClick={(e) => this.props.showThisComponent("menu")}
          >
            <span
              className={`ml-2 mr-2.5 font-semibold cap ${
                activeMenu ? `text-${site.primary_color}-500` : "text-gray-500"
              }`}
            >
              {language?.menu}
            </span>

            {Icon(
              "menu",
              activeMenu
                ? `stroke-${site.primary_color}-500`
                : "stroke-gray-400",
              "24"
            )}
          </span>
        </div>

        {this.props.visibility.menu && (
          <>
            <div className="VisibleMenu z-4 absolute border-1 top-6 md:top-2 right-0 mt-12 py-2 bg-white rounded-lg shadow-xl border border-gray-200 overflow-scroll">
              {[
                {
                  title: language?.home,
                  link: "/",
                  icon: "home",
                },
                {
                  title: language?.how_it_works,
                  link: "/about#how-it-works",
                  icon: "map",
                },
                {
                  title: language?.about,
                  link: "/about",
                  icon: "globe",
                },
                {
                  title: language?.values,
                  link: "/values",
                  icon: "values",
                },
              ].map((navItem) => {
                return (
                  <Link
                    to={navItem?.link}
                    key={navItem?.link}
                    className={`flex items-center px-4 pt-2.5 pb-2.5 hover:bg-gray-50 hover:text-${site.primary_color}-500`}
                  >
                    <span className="cap">{navItem?.title}</span>
                    <span className="ml-auto">
                      {Icon(navItem?.icon, "black", "18")}
                    </span>
                  </Link>
                );
              })}
              <div
                className="mt-3 mb-3 "
                style={{ backgroundColor: "#e6e6e6", height: "1px" }}
              ></div>

              <Link
                to={"/ai"}
                key={"/ai"}
                className={`flex items-center px-4 pt-2.5 pb-2.5 hover:bg-gray-50 hover:text-${site.primary_color}-500`}
              >
                <span className="cap">{language?.artificial_intelligence}</span>
                <span className="ml-auto">{Icon("robot", "black", "18")}</span>
              </Link>

              <span
                onClick={(e) => this.props.showThisComponent("match")}
                className={`hidden flex items-center px-4 pt-2.5 pb-2.5 hover:bg-gray-50 hover:text-${site.primary_color}-500 cursor-pointer`}
              >
                <span className="cap">{language?.match}</span>
                <span className="ml-auto">{Icon("match", "black", "18")}</span>
              </span>
            </div>
            <div
              className="popup-bg-transparent"
              onClick={(e) => this.props.showThisComponent("menu")}
            ></div>
          </>
        )}
      </div>
    );
  }
}

export default MenuRegular;
