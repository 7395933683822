import React, { Component } from 'react'
import FilterCheckInAndOut from './FilterCheckInAndOut';
import FilterLocation from './FilterLocation';

import Listings from '../listings/Listings';

export class DynamicPage extends Component {
  render() {
    const site = this.props.site;
    const language = this.props.language;
    const pageInformation = this.props.pageInformation;

    return (
      <div>
        <div className="relative max-w-8xl py-12 px-4 md:px-6 xl:px-0 m-auto">
          <div className="max-w-5xl pb-6">
            <h1 className="pb-4">{pageInformation?.title}</h1>
            <p className="text-xl leading-8	">{pageInformation?.description}</p>
          </div>

          <Listings
            pageInformation={pageInformation}
            site={site}
            language={language}
            pageType="dynamic"
            location={this.props.location}
          />
        </div>
      </div>
    );
  }
}

export default DynamicPage