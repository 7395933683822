import React, { Component } from 'react'
import { Icon, loadingPulse } from "../utils/utils";
import axios from "axios";
import { Link } from 'react-router-dom';

export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      results: [],
      loading: false,
      showSearchBox: false,
    };

    this.timeOut = 0;
    this.search = this.search.bind(this);
    this.saveSearchToLocalStorage = this.saveSearchToLocalStorage.bind(this);
  }

  componentDidMount() {
    const search = localStorage.getItem("search");
    
    if (search) {
      this.setState({ search: search });
    }
    else {
      this.saveSearchToLocalStorage()
    }
  }

  saveSearchToLocalStorage = () => {
    localStorage.setItem("search", this.state.search);
  };

  search = (e) => {
    e?.preventDefault();
    const form = document.querySelector("#SearchForm"); // Assumes you have only one form on the page

    form.addEventListener("keydown keyup", function (event) {
      if (event.key === "Enter" || event.keyCode === 13) {
        event.preventDefault();
        return false;
      }
    });

    const searchText = e?.target?.value ? e.target.value : this.state.search;
    this.setState({ search: searchText, loading: true });


    if (this.adminContentTimeout) clearTimeout(this.adminContentTimeout);
    this.adminContentTimeout = setTimeout(() => {
      axios.post(`/api/search`, { q: searchText }).then((res) => {
        this.setState({ results: res.data, loading: false });
        this.saveSearchToLocalStorage();
      });
    }, 200);
  };

  toggleSearchBox = () => {
    this.setState({ showSearchBox: !this.state.showSearchBox });
    this.search()
  };

  focusOnInput = () => {
    document.querySelector("#SearchForm input").focus();
  };

  render() {
    const site = this.props.site;
    const language = this.props.language;
    return (
      <div className="SeachComponentRelative">
        <div
          className="SearchComponent"
          onClick={(e) => this.toggleSearchBox()}
        >
          <form
            action=""
            className="w-full flex items-center px-4"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            onChange={(e) => {
              e.preventDefault();
              this.search(e);
              this.setState({ showSearchBox: true });
            }}
            id="SearchForm"
          >
            <input
              type="text"
              name="search"
              className="p-4 border w-full rounded cap"
              placeholder={language?.search_placeholder}
              defaultValue={this.state.search}
              style={{ border: "none", borderRadius: "20px", outline: "none" }}
            />
            <button
              type="button"
              className={`btn-regular btn-small rounded-full flex items-center ml-auto bg-${site.primary_color}-600`}
            >
              <span className="mr-1">{Icon("search", "white")}</span>
              <span className="font-normal cap">
                {language?.search_button_text}
              </span>
            </button>
          </form>
        </div>

        {this.state.showSearchBox && (
          <>
            <div className="VisibilitySearch z-2 pb-2">
              <div>
                <div
                  className={`p-4 bg-${site.primary_color}-50 text-${site.primary_color}-700 flex items-center`}
                  style={{ borderBottom: "1px solid #e6e6e6" }}
                >
                  <div
                    className={`cap font-bold text-${site.primary_color}-600`}
                  >
                    {language?.search}
                  </div>
                  <div className="ml-auto">
                    <div
                      className="cap cursor-pointer font-bold text-gray-600"
                      onClick={(e) => this.toggleSearchBox()}
                    >
                      {language?.close}
                    </div>
                  </div>
                </div>

                {this.state.loading && (
                  <div className="p-4" onClick={(e) => this.focusOnInput()}>
                    Laddar...
                  </div>
                )}
                {this.state.results.length === 0 &&
                  this.state.search.length > 0 &&
                  !this.state.loading && (
                    <div
                      className="px-4 pt-2"
                      onClick={(e) => this.focusOnInput()}
                    >
                      Ingen träff
                    </div>
                  )}

                {this.state.results.length === 0 &&
                  this.state.search.length === 0 &&
                  !this.state.loading && (
                    <div>
                      <div
                        className="px-4 pt-2"
                        onClick={(e) => this.focusOnInput()}
                      >
                        {language?.search_placeholder}
                      </div>
                    </div>
                  )}

                <div className="results">
                  {this.state.results.map((result, index) => {
                    return (
                      <Link
                        key={index}
                        className="Result p-4 border-b block"
                        to={result.slug}
                      >
                        <div className="flex items-center w-full">
                          {Icon("map")}
                          <span className="ml-2">{result.name}</span>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}

        {this.state.showSearchBox && (
          <div
            className="popup-bg-transparent"
            onClick={(e) => {
              {
                this.toggleSearchBox();
              }
            }}
          ></div>
        )}
      </div>
    );
  }
}

export default Search