import React, { Component } from 'react'

export class FilterLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],
      search: ""
    };

    this.renderCorrectLocationsBasedOnLanguage =
      this.renderCorrectLocationsBasedOnLanguage.bind(this);
  }

  componentDidMount() {
    this.renderCorrectLocationsBasedOnLanguage();
  }

  searchLocation(e) {
    e.preventDefault();
  }

  renderCorrectLocationsBasedOnLanguage() {
    const site = this.props.site;
    if (site.hreflang === "en") {
      [
        {
          name: "Europe",
        },
        {
          name: "Asia",
        },
        {
          name: "North America",
        },
        {
          name: "South America",
        },
        {
          name: "Africa",
        },
      ];
    } else if (site.hreflang === "sv") {
      [
        {
          name: "Monaco",
        },
        {
          name: "Frankrike",
        },
        {
          name: "Spaninen",
        },
        {
          name: "Stockholm",
        },
      ];
    } else {
      const locations = [
        {
          name: "Hà Nội",
        },
        {
          name: "Stockholm",
        },
        {
          name: "New York",
        },
        {
          name: "Madrid",
        },
      ];

      this.setState({ locations });
    }
  }

  render() {
    const site = this.props.site;
    const language = this.props.language;
    return (
      <div className="mt-4 grid gap-y-2 md:flex items-center ">
        <div className="Locations flex items-center overflow-x-scroll">
          {this.state.locations.map((location, index) => {
            return (
              <span key={index} className="flex items-center px-4 py-2 border border-dashed border-2 border-gray-200 rounded-full mr-2 text-sm font-semibold text-gray-500 cursor-pointer hover:bg-gray-50 whitespace-nowrap">
                {location.name}
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}

export default FilterLocation