import React, { Component } from 'react'

export class About extends Component {
  render() {
    const site = this.props.site;
    return (
      <div>
        <h1>{site?.about_title}</h1>
        <div dangerouslySetInnerHTML={{ __html: site?.about }} />
      </div>
    );
  }
}

export default About