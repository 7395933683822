import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';


export class ListingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true, 
      loading_images: true,
      listing: {},
      images: [],
      show_full_description: false,
      description_truncate_length: 400,
      isLightboxOpen: false,
      selectedImageIndex: 0,
    };

    this.loadListing = this.loadListing.bind(this);
    this.loadImages = this.loadImages.bind(this);
    this.showFullDescription = this.showFullDescription.bind(this);
    this.calculateHeightOfCoverImageBasedOnHeightOfScreen =
      this.calculateHeightOfCoverImageBasedOnHeightOfScreen.bind(this);
  }

  componentDidMount() {
    this.loadListing();
    document.addEventListener("keydown", this.handleKeyPress);
    document.addEventListener("keydown", this.closeLightbox);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
    document.removeEventListener("keydown", this.closeLightbox);
  }

  handleKeyPress = (e) => {
    if (this.state.isLightboxOpen) {
      if (e.key === "ArrowLeft") {
        this.navigateImage("previous");
      } else if (e.key === "ArrowRight") {
        this.navigateImage("next");
      }
    }
  };

  closeLightbox = (e) => {


    if (this.state.isLightboxOpen) {
      if (e.key === "Escape") {
        this.setState({
          isLightboxOpen: false,
          selectedImageIndex: 0
        });
      } 
    }
  };

  openLightbox = (index) => {
    console.log("openLightbox");
    console.log(index)
    this.loadImages(); // Load images if not already loaded
    setTimeout(() => {
      this.setState({
        isLightboxOpen: true,
        selectedImageIndex: index,
      });
    }, 0)
  };

  async loadImages() {
    if (this.state.images.length === 0) {
      try {
        const response = await axios.get(
          `/api/listing/${this.state.listing.id}/listing-photos`
        );
        this.setState({ images: response.data, loading_images: false });
      } catch (error) {
        console.error(error);
      }
    }
  }

  async loadListing() {
    var self = this;

    await axios
      .get(`/api/listing/${window.location.pathname.replace("/listing/", "/")}`)
      .then((response) => {
        self.setState({ listing: response.data, loading: false });
      })
      .then(() => {
        setTimeout(() => {
          this.calculateHeightOfCoverImageBasedOnHeightOfScreen();
        }, 250);
      });
  }

  showFullDescription() {
    this.setState({ show_full_description: true });
  }

  calculateHeightOfCoverImageBasedOnHeightOfScreen() {
    var coverImage = document.querySelector(".ListingMainImage");
    var windowHeight = window.innerHeight;

    const newCoverHeight = windowHeight * 0.55;
    if (newCoverHeight > 800) {
      coverImage.style.height = `${newCoverHeight * 0.6}px`;
    } else {
      coverImage.style.height = `${newCoverHeight}px`;
    }
  }

  // Function to navigate to the next or previous image in the Lightbox
  navigateImage = (direction) => {    
    this.setState((prevState) => {
      const lastIndex = prevState.images.length - 1;
      let newIndex;

      if (direction === "next") {
        newIndex =
          prevState.selectedImageIndex === lastIndex
            ? 0
            : prevState.selectedImageIndex + 1;
      } else if (direction === "previous") {
        newIndex =
          prevState.selectedImageIndex === 0
            ? lastIndex
            : prevState.selectedImageIndex - 1;
      } else {
        newIndex = prevState.selectedImageIndex; // No change for invalid direction
      }

      return { selectedImageIndex: newIndex };
    });
  };

  render() {
    const site = this.props.site;
    const listing = this.state.listing;
    const description = listing?.description || "";
    const truncatedDescription = `${description.substring(
      0,
      this.state.description_truncate_length
    )}...`;

    const coverImage =
      listing?.listing_photo_urls?.length > 0
        ? listing?.listing_photo_urls[0]
        : null;
    const buttonNavigationImageCss = `absolute top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg text-gray-600 hover:text-gray-800 text-xl focus:outline-none`;
    return (
      <div className="relative">
        {this.state.loading ? (
          <div>Loading...</div>
        ) : (
          <div className="max-w-8xl mx-auto py-6 sm:py-12 lg:py-20 px-6">
            <div className="grid grid-cols-12 gap-6 sm:gap-12 md:gap-16 lg:gap-20">
              <div className="col-span-12 sm:col-span-6 md:col-span-5">
                <div className="sticky top-6">
                  <img
                    src={coverImage}
                    alt={`${listing?.name}`}
                    loading="lazy"
                    className="w-full h-auto object-cover object-center rounded-lg ListingMainImage cursor-pointer"
                    onClick={() => this.openLightbox(0)}
                  />
                </div>

                {listing?.listing_photo_urls?.length > 1 && (
                  <div className="mt-4 ListingPhotoUrls">
                    <div className="grid grid-cols-3 gap-2 overflow-scroll">
                      {listing?.listing_photo_urls?.map((photo, index) => {
                        if (index > 0) {
                          return (
                            <img
                              key={index}
                              src={photo}
                              alt={`${listing?.name}`}
                              loading="lazy"
                              className={`w-full h-auto object-cover object-center rounded-lg cursor-pointer`}
                              onClick={() => this.openLightbox(index)}
                            />
                          );
                        }
                      })}
                    </div>
                  </div>
                )}

                {/* Lightbox */}
                {this.state.isLightboxOpen && (
                  
                    <div
                      className="lightbox fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-75"
                      onClick={() => this.setState({ isLightboxOpen: false })}
                    >
                      <div
                        className="bg-white shadow-lg rounded-lg p-4 w-full max-w-5xl"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {this.state.loading_images ? <div>Laddar...</div> : <>
                        <button
                          onClick={() =>
                            this.setState({ isLightboxOpen: false })
                          }
                          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-lg focus:outline-none"
                        >
                          Close
                        </button>
                        <img
                          src={this.state.images[this.state.selectedImageIndex]}
                          alt={`${listing?.name}`}
                          loading="lazy"
                          className="w-full h-auto object-contain"
                          style={{ maxHeight: "80vh" }}
                        />
                        <button
                          onClick={() => {
                            this.navigateImage("next");
                          }}
                          style={{ width: "40px", height: "40px" }}
                          className={`right-6 ${buttonNavigationImageCss}`}
                        >
                          &gt;
                        </button>
                        <button
                          onClick={() => {
                            this.navigateImage("previous");
                          }}
                          style={{ width: "40px", height: "40px" }}
                          className={`left-6 ${buttonNavigationImageCss}`}
                        >
                          &lt;
                        </button>
                        </>}
                      </div>
                    </div>
                )}
              </div>

              <div className="col-span-12 sm:col-span-6 md:col-span-7">
                {listing?.location && (
                  <div className="pb-4 text-gray-600">
                    {site?.discover_more_listings_in_place}{" "}
                    <Link
                      to={`/${listing?.location?.slug}`}
                      className={`text-${site?.primary_color}-600 hover:text-${site?.primary_color}-600 font-bold border-${site?.primary_color}-600 border-b-2`}
                    >
                      {listing?.location?.name}
                    </Link>
                  </div>
                )}
                <h1 className="text-4xl leading-none md:leading-normal">
                  {listing?.name}
                </h1>

                <p className="text-gray-700">
                  {this.state.show_full_description === false
                    ? truncatedDescription
                    : description}
                </p>

                {this.state.show_full_description === false &&
                  description.length >=
                    this.state.description_truncate_length && (
                    <span
                      className="cursor-pointer font-semibold"
                      onClick={(e) => this.showFullDescription()}
                    >
                      Läs mer
                    </span>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ListingPage