import React, { Component } from 'react'

export class Match extends Component {
  render() {
    const site = this.props.site;
    const language = this.props.language;
    return (
      this.props.visibility.match && (
        <div className="MatchPopup fixed inset-0 flex items-center justify-center z-80">
          <div
            className="fixed inset-0 bg-slate-900 opacity-80"
            onClick={(e) => this.props.showThisComponent("match")}
          ></div>
          <div className="relative max-w-3xl bg-white rounded-md shadow-xl p-12  SP-common-text mx-4">
            <div className="text-center">
              <h2 className="text-4xl">{site?.match_title}</h2>
              <p className="intro">{site?.match_summary}</p>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default Match