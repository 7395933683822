import React, { Component } from 'react'
import { Icon } from '../utils/utils'
import DatePicker from "react-date-picker";

export class FilterCheckInAndOut extends Component {
  constructor (props) {
    super(props)
    
    this.state = {
      visibleFilter: false,
      showGuests: false,
    }
  }
  render() {
    const site = this.props.site;
    const language = this.props.language;
    const today = new Date();

    return (
      <div>
        <div className="">
          <div className="block md:flex items-center">
            <div className="Dates grid gap-3 md:flex items-center">
              <div className="DatesTarget flex items-center">
                <div>
                  <div className="font-bold text-xs pb-1 cap text-gray-600">
                    {language?.check_in}
                  </div>
                  <div className="DatePicker mr-2">
                    <DatePicker value={today} />
                  </div>
                </div>
                <div>
                  <div className="font-bold text-xs pb-1 cap text-gray-600">
                    {language?.check_out}
                  </div>
                  <div className="DatePicker">
                    <DatePicker value={today} />
                  </div>
                </div>
              </div>
              <div className="ml-0 md:ml-6 relative max-w-xs">
                <div className="font-bold text-xs pb-1 cap text-gray-600">
                  {language?.guests}
                </div>
                <div className="DatePicker">
                  <div
                    className={`p-4 text-gray-600 Dropdown font-bold text-base flex items-center cursor-pointer hover:bg-gray-100 select-none`}
                    style={{
                      height: "40.8px",
                      minWidth: "134px",
                      backgroundColor: this.state.showGuests
                        ? "#e6e6e6"
                        : "#fafafa",
                    }}
                    onClick={(e) =>
                      this.setState({ showGuests: !this.state.showGuests })
                    }
                  >
                    <span>2 {language?.guests}</span>

                    {this.state.showGuests && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          strokeWidth={2}
                          className="ml-auto"
                        >
                          <path d="m4 4 11 11M15 4 4 15" />
                        </svg>

                        <div
                          className="popup-bg-transparent"
                          onClick={(e) =>
                            this.setState({
                              showGuests: !this.state.showGuests,
                            })
                          }
                        ></div>
                      </>
                    )}
                  </div>
                </div>

                {this.state.showGuests && (
                  <div
                    className="absolute bg-white border border-gray-200 border-1 rounded-lg mt-2  shadow-md"
                    style={{ width: "260px" }}
                  >
                    <div className="p-4">
                      <div className="">
                        <div className="flex items-center w-full mb-2">
                          <div className="font-bold text-base pb-1 cap text-gray-600">
                            {language?.adults}
                          </div>
                          <div className="ml-auto">
                            <div className="flex items-center font-bold text-lg">
                              + / -
                            </div>
                          </div>
                        </div>

                        <div className="flex items-center w-full">
                          <div className="font-bold text-base pb-1 cap text-gray-600">
                            {language?.children}
                          </div>
                          <div className="ml-auto">
                            <div className="flex items-center font-bold text-lg">
                              + / -
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="ml-auto mt-2 md:mt-0">
              <button
                className={`border border-1 rounded-full flex items-center justify-center px-4 py-2.5 cursor-pointer select-none ml-0 md:ml-4 bg-gray-50 hover:bg-gray-100 border-gray-200`}
                onClick={() =>
                  this.setState({ visibleFilter: !this.state.visibleFilter })
                }
              >
                <span className="mr-2">{Icon("filter")}</span>
                <span className="text-base">
                  {this.state.visibleFilter ? "Stäng" : "Visa"} filter
                </span>
              </button>
            </div>
          </div>

          {this.state.visibleFilter && (
            <div>
              <div className="p-4 bg-gray-50 rounded-lg mt-4 border border-gray-200 border-1">
                Here will filters go!!!!!!!!!!!!!!!!
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FilterCheckInAndOut