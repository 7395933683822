import React, { Component } from 'react'

import { Icon } from '../utils/utils';

import axios from "axios";
import Listing from "../listings/Listing";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";


export class ArticialIntelligence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      listings: [],
      questions: [],
      text: "",
      uuid: "",
      loading: true,
      navigation: false,
    };
    this.messagesEndRef = React.createRef(); // Create a reference

    this.sendMessage = this.sendMessage.bind(this);
    this.showAINavigation = this.showAINavigation.bind(this);
    this.createUUID = this.createUUID.bind(this);
    this.resizeTheChatWindow = this.resizeTheChatWindow.bind(this);
    this.arrayQuestions = this.arrayQuestions.bind(this);
    this.fetchlistings = this.fetchlistings.bind(this);
    this.selectOneOfRecommendations =
      this.selectOneOfRecommendations.bind(this);
  }

  componentDidMount() {
    // Write a function that creates a UUID if there is none in localStorage
    const siteId = document.querySelector("body").dataset.siteId;
    const uuid = `site_${siteId}_uuid`;

    if (!localStorage.getItem(uuid)) {
      localStorage.setItem(uuid, this.createUUID());
    }

    this.setState({ uuid: localStorage.getItem(uuid) }, () => {
      this.fetchConversation();
      // Use setTimeout to delay the execution of calculateChatWindowHeight
      setTimeout(() => {
        this.calculateChatWindowHeight();
        this.scrollToBottom();
        this.arrayQuestions(); 
      }, 0);
    });

    window.addEventListener("resize", this.resizeTheChatWindow);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeTheChatWindow);
  }

  resizeTheChatWindow() {
    this.calculateChatWindowHeight();
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the messages have finished loading
    if (prevState.loading && !this.state.loading) {
      this.calculateChatWindowHeight();
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    if (this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  createUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  fetchConversation() {
    axios
      .post(`/api/chat`, { uuid: this.state.uuid })
      .then((response) => {
        this.setState({ messages: response.data.messages });
      })
      .then(() => {
        this.setState({ loading: false });
        this.fetchlistings();
      });
  }

  async fetchlistings() {
    await axios
      .post(`/api/fetch-recommendations`, { uuid: this.state.uuid })
      .then((response) => {
        this.setState({ listings: response.data.listings });
      });
  }

  async sendMessage(e) {
    e.preventDefault();
    const inputElement = document.querySelector("input[name='content']");
    const content = inputElement.value;

    if (content.length == 0) {
      return false;
    }

    // Disable the input while loading
    inputElement.disabled = true;

    this.setState({
      messages: [
        ...this.state.messages,
        { from: "You", content: this.state.text },
      ],
      text: "",
      loading: true,
    });

    this.scrollToBottom();

    await axios
      .post("/api/send-message", {
        uuid: this.state.uuid,
        content: this.state.text,
      })
      .then((response) => {
        this.setState({
          text: "",
          messages: [...this.state.messages, response.data.response_message],
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        // Enable the input after loading is done
        inputElement.disabled = false;
      });

    this.fetchlistings();
  }

  calculateChatWindowHeight() {
    const windowHeight = window.innerHeight;
    const navigationRegular =
      document.querySelector(".NavigationRegular").clientHeight;
    const chatForm = document.querySelector(".ChatForm").clientHeight;

    const calcHeight = windowHeight - navigationRegular - chatForm;

    const chatWindow = document.querySelector(".ChatWindow");
    chatWindow.style.height = calcHeight + "px";

    const chat = document.querySelector(".Chat");
    chat.style.height = windowHeight - navigationRegular + "px";

    const body = document.querySelector("#app");
    body.style.overflow = "hidden";

    document.querySelector(".NavigationBackgroundImg").classList.add("hidden");
  }

  randomCity() {
    const cities = [
      "Dubai",
      "New York",
      "Stockholm",
      "Borlänge",
      "Paris",
      "Bangkok",
      "Tokyo",
      "San Francisco",
      "Venice",
      "Barcelona",
      "London",
      "Singapore",
      "Rome",
      "Istanbul",
      "Seoul",
      "Los Angeles",
      "Madrid",
      "Amsterdam",
      "Vienna",
      "Milan",
      "Prague",
      "Hong Kong",
      "Taipei",
    ];

    return cities[Math.floor(Math.random() * cities.length)];
  }

  selectOneOfRecommendations(message) {
    let input = document.querySelector(".Chat input[name='content']");
    input.value = message;

    this.setState({ text: message });
    setTimeout(() => {
      const form = document.querySelector(".Chat form button[type='submit']"); // Assumes you have only one form on the page
      form.click();
    }, 100);
  }

  arrayQuestions() {
    let questions;
    if (this.props.site.hreflang === "sv") {
      questions = [
        {
          title: `Vad ska man göra i ${this.randomCity()}?`,
          summary: "Om man gillar mat, dryck och museum",
        },
        {
          title: `Vilka är några bra hotel i ${this.randomCity()}?`,
          summary: "När man har en familj med barn",
        },
        {
          title: `Hur mycket kostar ett hotell i ${this.randomCity()}?`,
          summary: "Om man gillar unika, speciella hotell",
        },
        {
          title: `Vilket hotell rekommenderas i ${this.randomCity()}?`,
          summary: "Med en budget på 1000 kr per natt",
        },
      ];
    } else {
      questions = [
        {
          title: `What should I do in ${this.randomCity()}?`,
          summary: "If I like food, drinks and museum",
        },
        {
          title: `What are some great hotels in ${this.randomCity()}?`,
          summary: "When you have a family with kids",
        },
        {
          title: `How much is a hotel in ${this.randomCity()}?`,
          summary: "If you love unique, special hotels",
        },
        {
          title: `What hotel should I get in ${this.randomCity()}?`,
          summary: "With a budget of 100 USD per night",
        },
      ];
    }

    this.setState({ questions: questions });
  }

  showAINavigation() {
    this.setState({ navigation: !this.state.navigation });
  }

  createNewConversation() {
    localStorage.removeItem(`site_${this.props.site.id}_uuid`);
    
    this.setState({
      messages: [],
      listings: [],
      navigation: false,
    })
  }

  render() {
    const site = this.props.site;
    const language = this.props.language;
    return (
      <div className="Chat overflow-hidden">
        <div className="grid grid-cols-6 md:grid-cols-12">
          <div className="col-span-5 py-12 px-6 lg:p-12 ChatExplanation">
            <div className="max-w-3xl">
              <h1 className="text-4xl leading-normal">
                {site?.artificial_intelligence_title}
              </h1>
              <div
                className="text-xl leading-normal	text-gray-700"
                dangerouslySetInnerHTML={{
                  __html: site?.artificial_intelligence_description,
                }}
              ></div>
              {this.state.listings && this.state.listings.length > 0 && (
                <div className="pt-8">
                  <h4 className="pb-0 cap font-semibold">
                    {language.recommended} {language.hotels}
                  </h4>
                  <div className="Listings grid grid-cols-8 gap-3 md:gap-6 mt-6">
                    {this.state.listings.map((listing) => {
                      return (
                        <Listing
                          key={listing.id}
                          listing={listing}
                          site={site}
                          language={language}
                          multiple_images={false}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            style={{ borderLeftWidth: "1px" }}
            className={`ChatWindow col-span-7 h-screen overflow-y-auto ${
              this.state.messages.length === 0 ? "bg-white" : "bg-gray-50"
            } border-solid border-blue-200`}
          >
            <div className={`messages ${this.state.loading && "pb-12"}`}>
              {this.state.messages.length === 0 && (
                <div>
                  <div className="max-w-3xl p-6 md:p-12 md:pb-2">
                    <h2 className="max-w-xl">
                      {site?.questions_to_get_started_with_bot}
                    </h2>

                    <div
                      className="text-base text-gray-700"
                      dangerouslySetInnerHTML={{
                        __html:
                          site?.questions_to_get_started_with_bot_description,
                      }}
                    ></div>
                  </div>

                  <div className="p-6 md:p-12 md:pt-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {this.state.questions.map((recommendation) => {
                        return (
                          <div
                            className={`px-4 py-3 border rounded-lg col-span-1 hover:bg-gray-50 cursor-pointer truncate`}
                            onClick={(e) =>
                              this.selectOneOfRecommendations(
                                `${recommendation.title} ${recommendation.summary}`
                              )
                            }
                          >
                            <strong className="text-md font-bold truncate w-full pb-2">
                              {recommendation.title}
                            </strong>
                            <p className="text-sm text-gray-500 mb-0 pb-0">
                              {recommendation.summary}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {this.state.messages &&
                this.state.messages.map((message, index) => {
                  const isLastItem = index === this.state.messages.length - 1;
                  const marginBottom = isLastItem ? "0" : ""; // Use your desired margin value

                  return message["from"] === "chat_gpt" ? (
                    <div
                      key={index}
                      className="message p-2 bg-blue-50"
                      style={{ marginBottom: `${marginBottom}px` }}
                    >
                      <div className="flex items-center pb-4">
                        <div
                          className={`rounded-full bg-blue-500 flex items-center justify-center`}
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "4px",
                          }}
                        >
                          {Icon(
                            "robot",
                            "stroke-white text-white w-8 h-8 mr-4",
                            "20"
                          )}
                        </div>
                        <b className="ml-4 font-lg">
                          AI Travelagent from {this.props.site.name}{" "}
                        </b>
                      </div>

                      <div className="leading-6 text-base ChatContent">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {message["content"]}
                        </ReactMarkdown>
                      </div>
                    </div>
                  ) : (
                    <div key={index} className="message p-2 bg-gray-50">
                      <b>You</b>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {message["content"]}
                      </ReactMarkdown>
                    </div>
                  );
                })}
              <div ref={this.messagesEndRef} /> {/* Add this line */}
            </div>

            <div className="ChatForm fixed bottom-0 right-0">
              <div
                className={`p-4 bg-gray-50 font-bold ${
                  this.state.loading == true ? "" : "hidden"
                } mx-1`}
              >
                {site.ai_bot_loading}
              </div>
              <div
                className={`w-full bg-blue-50 p-4 md:p-8 flex items-start border border-solid border-blue-200 relative`}
              >
                <div
                  onClick={(e) => this.showAINavigation()}
                  className={`MagicIcon cursor-pointer w-14 h-14 p-3 hover:bg-blue-100 ${
                    this.state.navigation && "bg-blue-100"
                  } rounded-full flex items-center justify-center mr-4`}
                >
                  {Icon("magic", "stroke-blue-500 text-blue-500 w-7 h-7")}
                </div>

                {this.state.navigation && (
                  <div
                    className="absolute top-0 left-3 z-2 bg-white rounded-md border overflow-hidden"
                    style={{ width: "260px", top: "-10px" }}
                  >
                    <div
                      className="p-4 font-bold text-lg cursor-pointer hover:bg-gray-50 cap"
                      onClick={(e) => this.createNewConversation()}
                    >
                      {language.new} {language.conversation}
                    </div>
                  </div>
                )}

                <form onSubmit={this.sendMessage} className="w-full">
                  <input
                    type="text"
                    name="content"
                    value={this.state.text}
                    onChange={(e) => this.setState({ text: e.target.value })}
                    placeholder={site.ai_bot_placeholder}
                    className={`text-lg w-full bg-white p-4 rounded-lg border border-gray-200 outline-none text-gray-700`}
                  />

                  <button type="submit" className="send-button hidden">
                    Send
                  </button>
                  <div className="text-xs mt-2 text-gray-600">
                    {site.ai_bot_terms}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArticialIntelligence