import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import AppWrapper from "../components/AppWrapper";

document.addEventListener("DOMContentLoaded", () => {
  const root = document.getElementById("app");
  createRoot(root).render(
    <Router>
      <AppWrapper />
    </Router>
  );
});
